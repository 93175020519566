
import { FormController, IVForm } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { AlertModule } from '@/modules/alert/store'
import { UserModule } from '@/modules/user/store'

import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

@Component({
  name: 'OrganizationSharing'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)
  userModule = getModule(UserModule, this.$store)

  @Ref() readonly form!: IVForm
  @Ref() readonly domainInput!: HTMLElement

  loadingShareLinksToggle = false

  formController = new FormController({
    initialModel: {
      domains: ''
    }
  })

  get currentOrganizationId () {
    return this.$params.organizationId
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.currentOrganizationId)!
  }

  get shareLinksEnabled () {
    return this.currentOrganization.shareLinksEnabled
  }

  get formIcon () {
    if (this.formController.submitting) {
      return undefined
    } else if (this.formController.disabled) {
      return '$fas-pencil-alt'
    } else {
      return '$fas-check'
    }
  }

  get currentOrganizationLimits () {
    return this.organizationModule.organizationLimits(this.currentOrganization)
  }

  get organizationLimitShareLinkProtected () {
    return this.currentOrganizationLimits.shareLinkProtected
  }

  created () {
    this.formController.submitHandler = async model => {
      const shareLinkAuthDomains = model.domains.split(',').map(o => o.trim()).filter(o => o)

      await this.organizationModule.updateOrganization({
        organizationId: this.currentOrganization.id,
        props: {
          shareLinkAuthDomains: shareLinkAuthDomains.length ? shareLinkAuthDomains : null
        }
      })

      this.resetForm()

      analytics.organizationUpdate.track(this, {
        organizationId: [this.currentOrganization.id],
        organizationShareLinkAuthDomains: shareLinkAuthDomains.length ? shareLinkAuthDomains : null
      })
    }
  }

  mounted () {
    analytics.organizationSharingScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    this.formController.disabled = true
    this.formController.on('success', () => {
      this.formController.disabled = true
    })

    this.resetForm()
  }

  resetForm () {
    this.formController.setInitialModel({
      domains: this.currentOrganization.shareLinkAuthDomains?.join(', ') || ''
    })
    this.formController.resetModel()
  }

  formSubmit () {
    if (!this.formController.submitting) {
      if (this.formController.disabled) {
        this.formController.disabled = false
        this.domainInput.focus()
      } else {
        this.formController.submit(this.form)
      }
    }
  }

  async shareLinksToggle () {
    try {
      if (this.loadingShareLinksToggle) {
        return
      }
      this.loadingShareLinksToggle = true

      const shareLinksEnabled = !this.shareLinksEnabled

      await this.organizationModule.updateOrganization({
        organizationId: this.currentOrganizationId,
        props: {
          shareLinksEnabled
        }
      })

      analytics.organizationUpdate.track(this, {
        organizationId: [this.currentOrganization.id],
        organizationShareLinksEnabled: shareLinksEnabled
      })

      this.resetForm()
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loadingShareLinksToggle = false
    }
  }
}
